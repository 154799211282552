<template>
  <div>
    <table
      test="user-redeemables-table"
      class="
        uk-table uk-table-divider uk-table-hover uk-table-middle uk-visible@s
      "
    >
      <thead>
        <th width="10%" class="uk-text-center">Cantidad</th>
        <th width="15%" class="uk-text-center">Imágen</th>
        <th width="25%" class="uk-text-center">Producto</th>
        <th width="20%" class="uk-text-center">Precio</th>
        <th width="10%" class="uk-text-center">Agregado el</th>
        <th width="20%" class="uk-text-right" v-if="editable"></th>
      </thead>
      <tbody v-if="!isLoading">
        <tr v-for="userQuotable in userQuotables" :key="userQuotable.id">
          <td class="uk-text-center">
            <div>{{ userQuotable.quantity }}</div>
            <div>({{ userQuotable.quotable.current_stock }} disponibles)</div>
          </td>
          <td class="uk-text-center">
            <router-link
              v-if="userQuotable.quotable.media.length"
              :to="
                '/store/' +
                (userQuotable.quotable.redeemable_type ===
                'App\\Models\\Product'
                  ? userQuotable.quotable.redeemable.id
                  : userQuotable.quotable.redeemable.product.id)
              "
            >
              <img
                width="100%"
                :src="userQuotable.quotable.media[0].url"
                alt=""
              />
            </router-link>
          </td>
          <td class="uk-text-center">
            <router-link
              :to="
                '/store/' +
                (userQuotable.quotable.redeemable_type ===
                'App\\Models\\Product'
                  ? userQuotable.quotable.redeemable.id
                  : userQuotable.quotable.redeemable.product.id)
              "
            >
              {{
                userQuotable.quotable.redeemable_type === "App\\Models\\Product"
                  ? userQuotable.quotable.display_name
                  : userQuotable.quotable.redeemable.product
                      .storefront_redeemables[0].display_name +
                    " - " +
                    userQuotable.quotable.redeemable.value
              }}
            </router-link>
          </td>
          <td class="uk-text-center">
            <img v-if="pointsIcon" :src="pointsIcon" alt="" width="50px" />
            {{ formatThousands(userQuotable.quotable.current_price) }}
            {{ pointsName }}
          </td>
          <td class="uk-text-center">
            {{ formatDatetime(userQuotable.created_at) }}
          </td>
          <td class="uk-text-right" v-if="editable">
            <div class="uk-text-right">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <button
                  v-if="userQuotable.saved_for_later === 0"
                  class="uk-button uk-button-default"
                  @click="saveForLater(userQuotable.id)"
                >
                  <div uk-icon="future" class="icon"></div>
                </button>
                <button
                  v-else
                  class="uk-button uk-button-default"
                  @click="moveToCart(userQuotable.id)"
                >
                  <div uk-icon="cart" class="icon"></div>
                </button>
                <button
                  class="uk-button uk-button-default"
                  :href="'#edit-userQuotable-modal-' + tableId"
                  uk-toggle
                  @click="setEditMode(userQuotable)"
                >
                  <div uk-icon="pencil" class="icon"></div>
                </button>
                <button
                  class="uk-button uk-button-default"
                  :href="'#delete-userQuotable-modal-' + tableId"
                  uk-toggle
                  @click="setEditMode(userQuotable)"
                >
                  <div uk-icon="trash" class="icon"></div>
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="uk-text-right">Total</td>
          <td class="uk-text-center">
            <img v-if="pointsIcon" :src="pointsIcon" alt="" width="50px" />
            {{
              formatThousands(
                userQuotables.reduce(
                  (accum, userQuotable) =>
                    accum +
                    userQuotable.quotable.current_price * userQuotable.quantity,
                  0
                )
              )
            }}
            {{ pointsName }}
          </td>
          <td></td>
          <td v-if="editable"></td>
        </tr>
      </tbody>
    </table>

    <div class="uk-hidden@s uk-margin-top">
      <div v-if="!isLoading">
        <div v-for="userQuotable in userQuotables" :key="userQuotable.id">
          <div class="uk-flex">
            <div class="uk-width-1-4">
              <router-link
                v-if="userQuotable.quotable.media.length"
                :to="
                  '/store/' +
                  (userQuotable.quotable.redeemable_type ===
                  'App\\Models\\Product'
                    ? userQuotable.quotable.redeemable.id
                    : userQuotable.quotable.redeemable.product.id)
                "
              >
                <img
                  width="100px"
                  :src="userQuotable.quotable.media[0].url"
                  alt=""
                />
              </router-link>
            </div>
            <div class="uk-width-3-4">
              <router-link
                :to="
                  '/store/' +
                  (userQuotable.quotable.redeemable_type ===
                  'App\\Models\\Product'
                    ? userQuotable.quotable.redeemable.id
                    : userQuotable.quotable.redeemable.product.id)
                "
              >
                {{
                  userQuotable.quotable.redeemable_type ===
                  "App\\Models\\Product"
                    ? userQuotable.quotable.display_name
                    : userQuotable.quotable.redeemable.product
                        .storefront_redeemables[0].display_name +
                      " - " +
                      userQuotable.quotable.redeemable.value
                }}
              </router-link>
              <div>Cantidad: {{ userQuotable.quantity }}</div>
              <div>
                Precio:
                <img v-if="pointsIcon" :src="pointsIcon" alt="" width="50px" />
                {{ formatThousands(userQuotable.quotable.current_price) }}
                {{ pointsName }}
              </div>
              <div>
                Agregado el: {{ formatDatetime(userQuotable.created_at) }}
              </div>
            </div>
          </div>
          <div class="uk-text-center uk-margin-small-top">
            <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
              <button
                v-if="userQuotable.saved_for_later === 0"
                class="uk-button uk-button-default"
                @click="saveForLater(userQuotable.id)"
              >
                <div uk-icon="future" class="icon"></div>
              </button>
              <button
                v-else
                class="uk-button uk-button-default"
                @click="moveToCart(userQuotable.id)"
              >
                <div uk-icon="cart" class="icon"></div>
              </button>
              <button
                class="uk-button uk-button-default"
                :href="'#edit-userQuotable-modal-' + tableId"
                uk-toggle
                @click="setEditMode(userQuotable)"
              >
                <div uk-icon="pencil" class="icon"></div>
              </button>
              <button
                class="uk-button uk-button-default"
                :href="'#delete-userQuotable-modal-' + tableId"
                uk-toggle
                @click="setEditMode(userQuotable)"
              >
                <div uk-icon="trash" class="icon"></div>
              </button>
            </div>
          </div>
          <hr class="uk-divider-icon" />
        </div>
      </div>
    </div>

    <div
      :id="'edit-userQuotable-modal-' + tableId"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="
          uk-modal-dialog
          uk-modal-body
          uk-margin-auto-vertical
          uk-border-rounded
          uk-overflow-hidden
        "
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Crear</span>
          <span v-else>Editar</span>
          producto
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider
            name="Cantidad"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Cantidad</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Cantidad"
              v-model="model.quantity"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="uk-text-center uk-margin-top">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              :disabled="invalid || isLoading || isEditing"
              @click="updateQuotable(selectedUserQuotable.id)"
            >
              <span v-if="isLoading || isEditing" uk-spinner></span>
              <span v-else> Editar producto </span>
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <div
      :id="'delete-userQuotable-modal-' + tableId"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="
          uk-modal-dialog
          uk-modal-body
          uk-margin-auto-vertical
          uk-border-rounded
          uk-overflow-hidden
        "
        v-if="selectedUserQuotable"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          ¿Está seguro que desea eliminar el producto
          {{
            selectedUserQuotable.quotable_type === "App\\Models\\Product"
              ? selectedUserQuotable.quotable.name
              : selectedUserQuotable.quotable_type ===
                "App\\Models\\ProductVariation"
              ? selectedUserQuotable.quotable.product.name
              : ""
          }}?
        </div>

        <div class="uk-text-center uk-margin-top">
          <button
            class="uk-button uk-button-danger uk-border-rounded"
            @click="removeQuotable(selectedUserQuotable.id)"
            :disabled="isLoading || isEditing"
          >
            <span v-if="isLoading || isEditing" uk-spinner></span>
            <span v-else>Eliminar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UIkit from "uikit";

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export default {
  name: "UserQuotablesRedeemTable",

  props: ["userQuotables", "editable", "isLoading"],

  data() {
    return {
      tableId: uuidv4(),
      model: {
        quantity: null,
      },
      selectedUserQuotable: null,
      mode: "edit",
      isEditing: false,
    };
  },

  computed: {
    ...mapGetters(["storefrontStorefront", "pointsName", "pointsIcon"]),
  },

  methods: {
    saveForLater(userQuotableId) {
      this.isEditing = true;
      this.axios
        .put("/quotes/product/" + userQuotableId + "/save-for-later")
        .then(() => {
          UIkit.notification({
            message: '<span uk-icon="icon: check"></span> Producto guardado.',
            pos: "bottom-left",
          });
          this.$router.go();
        })
        .finally(() => {
          this.isEditing = false;
        });
    },
    moveToCart(userQuotableId) {
      this.isEditing = true;
      this.axios
        .put("/quotes/product/" + userQuotableId + "/move-to-cart")
        .then(() => {
          UIkit.notification({
            message: '<span uk-icon="icon: check"></span> Producto en carrito.',
            pos: "bottom-left",
          });
          this.$router.go();
        })
        .finally(() => {
          this.isEditing = false;
        });
    },
    setEditMode(userQuotable) {
      this.mode = "edit";
      this.selectedUserQuotable = userQuotable;
      this.model.quantity = userQuotable.quantity;
    },
    updateQuotable(userQuotableId) {
      this.isEditing = true;
      this.axios
        .put("/quotes/product/" + userQuotableId, this.model)
        .then(() => {
          UIkit.notification({
            message:
              '<span uk-icon="icon: check"></span> Producto actualizado.',
            pos: "bottom-left",
          });
          this.$router.go();
          UIkit.modal("#edit-userQuotable-modal-" + this.tableId).hide();
        })
        .finally(() => {
          this.isEditing = false;
        });
    },
    removeQuotable(userQuotableId) {
      this.isEditing = true;
      this.axios
        .delete("/quotes/product/" + userQuotableId)
        .then(() => {
          UIkit.notification({
            message:
              '<span uk-icon="icon: check"></span> Producto removido de tu cotización.',
            pos: "bottom-left",
          });
          this.$router.go();
          UIkit.modal("#delete-userQuotable-modal-" + this.tableId).hide();
        })
        .finally(() => {
          this.isEditing = false;
        });
    },
  },
};
</script>

<style scoped>
.icon {
  min-width: 20px;
}
</style>